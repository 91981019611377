<template>
    <modal ref="modalVerMas" :titulo="titulo" tamano="modal-lg" no-aceptar no-cancelar>
        <div class="row mx-0 justify-center">
            <div v-for="(d, key) in datosGenerales" :key="key" class="col-8 mb-4">
                <div class="row mx-0 mb-1">
                    <div class="col-auto px-0 f-16 text-general f-500">
                        {{ d.nombre }}                        
                    </div>
                    <div class="col" />
                    <div v-if="infoModal.tipo == 1 || infoModal.tipo == 2" class="col-auto px-2 d-middle-center">
                        <i class="icon-eye f-12" />
                        <span class="mx-2 text-grey f-13">
                            {{ d.visitas }}
                        </span>
                    </div>
                    <div v-else class="col-auto px-2 d-middle-center">
                        <i class="icon-cursor" />
                        <span class="mx-2 text-grey f-13">
                            {{ d.clicks }}
                        </span>
                    </div>
                    <div v-if="infoModal.tipo == 1" class="col-auto px-0 d-middle-center">
                        <i class="icon-cliente" />
                        <span class="mx-2 text-grey f-14">
                            {{ d.clientes }}
                        </span>
                    </div>
                </div>
                <div class="row mx-0" style="height:8px;background-color: #DFE4E8;">
                    <div class="h-100 bg-general" :style="`width:${d.porcentaje}%`" />
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
import Estadistica from '~/services/landing/landingEstadisticas'
export default {
    props: {
        titulo: {
            type: String,
            default: ''
        }
    },
    data(){
        return {
            infoModal: {},
            datosGenerales: []
        }
    },
    methods: {
        toggle(datos){
            this.infoModal = datos;
            this.$refs.modalVerMas.toggle();
            this.getDatos()
        },
        async getDatos(){
            try {
                const { data } = await Estadistica.getDatos(this.infoModal);
                this.datosGenerales = data?.data
            } catch (e){
                this.error_catch(e)
            } 
        }
    }
}
</script>
